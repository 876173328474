export const getLevel = (xp: number) => {
  // 0-3 --> 0
  // 4-9 --> 1
  // 10-27 --> 2
  // 28-81 --> 3
  // 82-243 --> 4
  // 244 (cap) --> 5
  if (xp < 4) {
    return { lvl: 0, xp: xp, left: 4, progress: ((xp - 0) / 4) * 100 };
  } else if (xp >= 4 && xp < 10) {
    return { lvl: 1, xp: xp - 4, left: 6, progress: ((xp - 4) / 6) * 100 };
  } else if (xp >= 10 && xp < 28) {
    return { lvl: 2, xp: xp - 10, left: 18, progress: ((xp - 10) / 18) * 100 };
  } else if (xp >= 28 && xp < 82) {
    return { lvl: 3, xp: xp - 28, left: 54, progress: ((xp - 28) / 54) * 100 };
  } else if (xp >= 82 && xp < 244) {
    return {
      lvl: 4,
      xp: xp - 82,
      left: 162,
      progress: ((xp - 82) / 162) * 100,
    };
  } else if (xp >= 244 && xp < 564) {
    return {
      lvl: 5,
      xp: xp - 244,
      left: 320,
      progress: ((xp - 244) / 320) * 100,
    };
  }
  return { lvl: 6, xp: 0, left: 0, progress: 1 * 100 };
};
