import React from "react";

import { Typography, Box } from "@mui/material";
import { useWallet } from "@solana/wallet-adapter-react";
import { Route, Routes } from "react-router-dom";
import { Wallet } from "./components/Wallet";

import Home from "./pages/Home";
import Match from "./pages/Match";
import { NotFound } from "./pages/NotFound";

export const Router = () => {
  const { publicKey } = useWallet();
  return (
    <Routes>
      <Route path="/" element={publicKey ? <Home /> : <ConnectWallet />} />
      <Route
        path="/match/:challengeId"
        element={publicKey ? <Match /> : <ConnectWallet />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const ConnectWallet = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        className="glass-container"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" gutterBottom align="center">
          Connect your wallet to see your QTbotz!
        </Typography>
        <Typography variant="subtitle2" align="center">
          Note: This is an alpha version of the QTbotz game.
        </Typography>
        <br />
        <Wallet />
      </Box>
    </Box>
  );
};
