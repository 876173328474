import { Box, LinearProgress, styled, Typography } from "@mui/material";
import { Nft } from "../generated/graphql";
import { getLevel } from "../util";

interface NFTCardProps {
  nft?: Nft;
  home?: boolean;
}

const NFTContainer = styled("a")(({ theme }) => ({
  textDecoration: "none",
  display: "flex",
  flexDirection: "column",
  borderRadius: 5,
  cursor: "pointer",
}));

const Container = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  [theme.breakpoints.up("sm")]: {
    height: "100%",
    width: "100%",
  },
}));

const WrapImg = styled(Box)(({ theme }) => ({
  borderRadius: 7,
  overflow: "hidden",
  transition: "all .2s ease-in-out",
  "&:hover": {
    transform: "scale(1.03)",
    transition: "all .2 ease-in-out",
  },
  width: "auto",
  height: "auto",
  maxWidth: 175,
  maxHeight: 175,
  [theme.breakpoints.up("sm")]: {
    width: 175,
    height: 175,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: 200,
    height: 200,
  },
}));

const WrapData = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: 130,

  [theme.breakpoints.up("sm")]: {
    width: 175,
  },
  [theme.breakpoints.up("md")]: {
    width: 200,
  },
  "& .expContainer": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 20,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
  },
  "& .levelContainer": {
    display: "flex",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProgressBar = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .MuiLinearProgress-root": {
    background: "#FFF",
    borderRadius: "10px",
  },
}));

const NameStyled = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  textAlign: "center",
  fontWeight: 500,
  fontSize: 11,
  marginBottom: 4,
  marginTop: 4,
  [theme.breakpoints.up("sm")]: { fontSize: 13, marginTop: 8, marginBottom: 8 },
  [theme.breakpoints.up("md")]: { fontSize: 17 },
}));

const SpanLevel = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  [theme.breakpoints.up("sm")]: {
    fontSize: 12,
  },
}));

const SpanValue = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  marginLeft: 4,
  fontStyle: "italic",
  [theme.breakpoints.up("sm")]: {
    fontSize: 25,
  },
}));

const TypographyExp = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontWeight: 500,
  fontSize: 10,
  "& span": {
    marginLeft: 5,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 12,
  },
}));

export const NFTCard: React.FC<NFTCardProps> = ({ nft, home }) => {
  const { lvl, xp, left, progress } = getLevel(nft?.xp || 0); // nft.xp
  const imgSrc = nft?.img || "/looking.gif";

  return (
    <NFTContainer
      href={nft?.mintid && "https://solscan.io/token/" + nft?.mintid}
      target="_blank"
      style={
        home
          ? {}
          : lvl === 6
          ? {
              background:
                "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
              backdropFilter: "blur(20px)",
              padding: "10px 20px 20px 20px",
              borderRadius: 7,
            }
          : {
              background:
                "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
              backdropFilter: "blur(20px)",
              padding: "10px 20px 20px 20px",
              borderRadius: 7,
            }
      }
    >
      <NameStyled>{nft?.name || "QTbot #..."}</NameStyled>
      <Container>
        <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <WrapImg>
            <img
              src={imgSrc}
              style={{ height: "100%", width: "100%", borderRadius: "7px" }}
              alt={nft?.name}
            />
          </WrapImg>
        </span>

        <WrapData>
          <div className="expContainer">
            {nft !== undefined && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TypographyExp variant="body2" color="text.secondary">
                    {`EXP: `}
                    <span>{nft ? `${xp} / ${left}` : "..."}</span>
                  </TypographyExp>
                  <div className="levelContainer">
                    <SpanLevel>LEVEL</SpanLevel>
                    <SpanValue>{nft ? lvl : "..."}</SpanValue>
                  </div>
                </Box>

                <ProgressBar>
                  <LinearProgress variant="determinate" value={progress} />
                </ProgressBar>
              </>
            )}
          </div>
        </WrapData>
      </Container>
    </NFTContainer>
  );
};
