/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { joinChallenge, useProvider } from "./join";
import { Context } from "urql";
import { PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  GetMatchDocument,
  useQueueWagerMatchMutation,
  useVerifySignatureMutation,
} from "../generated/graphql";

const useClient = () => React.useContext(Context);

export const useBattleJoin = (
  mint: string,
  entry: number,
  func: (id: number) => void
) => {
  const { connection, provider } = useProvider();
  const gqlClient = useClient();
  const wallet = useWallet();
  const anchorWallet = useAnchorWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const [ata, setAta] = useState("");

  const [{}, createWager] = useQueueWagerMatchMutation();
  const [{}, verififySig] = useVerifySignatureMutation();

  useEffect(() => {
    const func = async () => {
      try {
        const { ata, amount } = await getAtaAndAmount(
          mint,
          wallet.publicKey!,
          connection
        );

        setAta(ata.toString());

        if (amount >= entry) {
          setCanJoin(true);
        } else if (
          mint === NATIVE_MINT.toString() &&
          (await connection.getBalance(wallet.publicKey!)) + amount >= entry
        ) {
          setCanJoin(true);
        }
      } catch (e) {}
    };
    func();
  }, [wallet.publicKey?.toString() || ""]);

  const join = useMemo(
    () => async (nft: string) => {
      if (!canJoin || isLoading || ata === "") return;

      try {
        setIsLoading(true);
        const id = (await createWager({ mintid: nft })).data?.createWager!;
        const queryData = await toast.promise(
          retryTillCondition(
            gqlClient.query(
              GetMatchDocument,
              { id },
              { requestPolicy: "network-only" }
            ).toPromise,
            (match) => {
              return match.data?.getMatch?.wagerId !== null;
            }
          ),
          {
            loading: "Creating challenge on chain... Hang tight!",
            success: "Challenge has been created on chain!",
            error: "Challenge creation failed!",
          }
        );
        // @ts-expect-error
        const joinable: string = queryData.data?.getMatch?.wagerId;
        await joinChallenge(
          provider,
          anchorWallet!,
          wallet,
          new PublicKey(joinable),
          new PublicKey(ata),
          new PublicKey(mint),
          async (sig) => {
            await verififySig({ id: id, signature: sig });
          }
        );
        setIsLoading(false);
        func(id);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [ata, canJoin]
  );

  return { isLoading, canJoin, join };
};

export const getAtaAndAmount = async (
  mint: string,
  wallet: PublicKey,
  connection: anchor.web3.Connection
) => {
  const ata = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    new PublicKey(mint),
    wallet
  );
  let amount = 0;
  try {
    const tokenAmount = await connection.getTokenAccountBalance(ata);
    amount = parseFloat(tokenAmount.value.amount);
    return { ata, amount };
  } catch (err) {
    return { ata, amount };
  }
};

const retryTillCondition = async <T>(
  func: () => Promise<T>,
  condition: (arg: T) => boolean
) => {
  let val = null;
  do {
    // @ts-expect-error
    val = await func();
    await sleep(1000);
    // @ts-expect-error
  } while (!condition(val));
  return val;
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

// function useInterval(callback: () => void, delay: number | null) {
//   const savedCallback = useRef(callback);

//   // Remember the latest callback if it changes.
//   useLayoutEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     // Don't schedule if no delay is specified.
//     // Note: 0 is a valid value for delay.
//     if (!delay && delay !== 0) {
//       return;
//     }

//     const id = setInterval(() => savedCallback.current(), delay);

//     return () => clearInterval(id);
//   }, [delay]);
// }
