import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Confetti from "react-confetti";
import { Box, Button, Container, Dialog, styled, Alert } from "@mui/material";
import { NFTCard } from "../components/NFTCard";
import {
  useGetMatchQuery,
  useResolveWagerMutation,
  useQueueMatchMutation,
} from "../generated/graphql";
import { useWallet } from "@solana/wallet-adapter-react";
import { useProvider } from "../solanya/join";
import * as anchor from "@project-serum/anchor";
import { Challenge, IDL } from "../types/challenge";
import { getLevel } from "../util";

// Battle Animation
import Lottie from "react-lottie";
import userWin from "../components/leftwinner.json";
import opponentWin from "../components/rightwinner.json";

const TrophyContainer = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 10,
  [theme.breakpoints.up("sm")]: {
    marginBottom: 0,
  },
  "& .winnerImage": {
    width: 70,
    height: 70,
    marginBottom: 5,
    [theme.breakpoints.up("sm")]: {
      width: 100,
      height: 100,
    },
  },
  "& .winnings": {
    color: "#FFF",
    background: "rgba(72, 231, 36, 0.75)",
    borderRadius: 12,
    border: "1px solid rgba(209, 213, 219, 0.3)",
    padding: "0.5rem 1rem",
    fontSize: 20,
    fontWeight: 700,
  },
}));

const VSContainer = styled("span")(({ theme }) => ({
  height: 70,
  fontWeight: 900,
  fontSize: 50,
  color: "#FFF",
}));

const EntreFeeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 10,
  marginTop: 10,
  color: "#FFF",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  padding: 5,
  borderRadius: 8,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  "& .prize": {
    fontWeight: 700,
    fontSize: 16,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      fontSize: 30,
      marginLeft: 5,
    },
    "& img": {
      marginLeft: 5,
      height: 30,
      width: 30,
    },
  },
  "& .entryFee": {
    fontWeight: 700,
    fontSize: 15,
    background:
      "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
    backdropFilter: "blur(20px)",
    width: "100%",
    textAlign: "center",
    borderRadius: 6,
    padding: "5px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      marginLeft: 5,
      height: 20,
      width: 20,
    },
  },
}));

const QTContainerTop = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const QTContainerBottom = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  marginTop: 32,
  marginBottom: 16,
  paddingTop: 16,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    marginTop: 56,
  },
}));

const DialogStyled = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    overflow: "hidden",
    background: "linear-gradient( to bottom, #e5edff 20%, #f99aff 100% )",
    borderRadius: "25px",
  },
}));

const FirstPlace = styled("span")(({ theme }) => ({
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  borderRadius: "12px",
  border: "1px solid rgba(209, 213, 219, 0.3)",
  padding: "0.5rem 1rem",
  marginBottom: "1rem",
  fontWeight: 700,
  color: "#FFF",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
    marginLeft: 0,
  },
}));

const SecondPlace = styled("span")(({ theme }) => ({
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  borderRadius: "12px",
  border: "1px solid rgba(209, 213, 219, 0.3)",
  padding: "0.5rem 1rem",
  marginBottom: "1rem",
  fontWeight: 700,
  color: "#FFF",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
    marginRight: 0,
  },
}));

const AlertGlass = styled(Alert)(({ theme }) => ({
  background:
    "linear-gradient(143.72deg, rgba(252, 217, 105, 0.33) 71.17%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  color: "#FFF",
  borderRadius: 15,
  fontWeight: 550,
  "& .MuiAlert-icon": {
    color: "#ffe49c",
  },
}));

export default function Match() {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const { challengeId } = useParams();
  const { provider } = useProvider();
  const id = useMemo(() => parseInt(challengeId || ""), [challengeId]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [didGifPlay, setDidGifPlay] = useState(false);

  // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-unused-vars
  const [{}, queueMatch] = useQueueMatchMutation();
  // eslint-disable-next-line no-empty-pattern
  const [{}, resolveWager] = useResolveWagerMutation();

  const [{ data: matchData, error: matchDataError }, getMatch] =
    useGetMatchQuery({
      variables: { id },
      requestPolicy: "network-only",
    });

  const winner = matchData?.getMatch.winner;
  const isWager = matchData?.getMatch?.isWager;
  const wagerId = matchData?.getMatch?.wagerId;
  const battleBots = matchData?.getMatch?.battleBots;

  const userQt =
    battleBots && battleBots.find((bb) => bb?.owner === publicKey?.toString());

  const opponentQt =
    battleBots && battleBots.find((bb) => bb?.owner !== publicKey?.toString());

  useEffect(() => {
    if (isNaN(id)) navigate("/");
    if (
      battleBots &&
      battleBots.find((bb) => bb?.owner === publicKey?.toString()) === undefined
    ) {
      navigate("/");
    }
  }, [id, navigate, battleBots, publicKey]);

  useEffect(() => {
    if (matchDataError) toast.error(`ERR: ${matchDataError?.message}`);
  }, [matchDataError]);

  useEffect(() => {
    if (winner) setDialogOpen(true);
  }, [winner]);

  useEffect(() => {
    if (didGifPlay && winner) {
      const playersBot = matchData?.getMatch?.battleBots?.find(
        (bot) => bot?.owner === publicKey?.toString()
      );

      const { lvl } = getLevel(playersBot?.xp || 0);
      const amount = lvl * 500;

      if (lvl !== 0)
        toast.success("You earned " + amount.toString() + " BONK!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didGifPlay && winner]);

  useEffect(() => {
    if (wagerId && winner) {
      toast.dismiss();
    } else if (wagerId) {
      toast.loading(
        "If you don't get the match result within 5 minutes, please send the match number in the Discord channel #🚨│report-an-issue and @hungman.sol\n" +
          "(Find the match number in the URL)"
      );
    }
    //eslint-disable-next-line
  }, [matchData?.getMatch.winner, matchData?.getMatch.wagerId]);

  useInterval(
    () => {
      getMatch();
      const resolve = async () => {
        if (wagerId && (winner === null || winner === undefined)) {
          const program = new anchor.Program<Challenge>(
            IDL,
            new anchor.web3.PublicKey(process.env.REACT_APP_SPL_PROGRAMID!),
            provider
          );
          const challenge = await program.account.challenge.fetch(wagerId);

          try {
            if (challenge.playersJoined === 2) await resolveWager({ id: id });
          } catch (err) {}
        }
      };
      resolve();
    },
    // Delay in milliseconds or null to stop it
    winner === null ? 10000 : null
  );

  return (
    <>
      <ContainerStyled>
        {isWager &&
          didGifPlay &&
          winner === userQt?.owner &&
          winner !== undefined && (
            <TrophyContainer>
              <img
                src={"/winner.png"}
                className="winnerImage"
                alt="Game icon vector created by upklyak - www.freepik.com"
              />
              <div className="winnings">0.05 SOL</div>
            </TrophyContainer>
          )}
        {isWager && !didGifPlay && (
          <EntreFeeContainer>
            <div className="prize">
              PRIZE: <span>0.05</span> <img src="/solCoin.png" alt="solCoin" />
            </div>
            <span className="entryFee">
              Entry Fee: 0.03
              <img src="/solCoin.png" alt="solCoin" />
            </span>
          </EntreFeeContainer>
        )}
        <MainContainer>
          {didGifPlay && winner === publicKey?.toString() && (
            <Confetti numberOfPieces={400} />
          )}

          {matchData && (
            <>
              <QTContainerTop>
                {!didGifPlay ? null : winner === userQt?.owner ? (
                  <FirstPlace>1st place</FirstPlace>
                ) : (
                  <SecondPlace>2nd place</SecondPlace>
                )}
                <div
                  className={
                    didGifPlay && winner === userQt?.owner ? "dance" : ""
                  }
                >
                  <NFTCard nft={userQt!} />
                </div>
              </QTContainerTop>
              <VSContainer>VS</VSContainer>

              {battleBots && battleBots.length > 1 ? (
                <QTContainerBottom>
                  {!didGifPlay ? null : winner === opponentQt?.owner ? (
                    <FirstPlace>1st place</FirstPlace>
                  ) : (
                    <SecondPlace>2nd place</SecondPlace>
                  )}
                  <div
                    className={
                      didGifPlay && winner === opponentQt?.owner ? "dance" : ""
                    }
                  >
                    <NFTCard nft={opponentQt!} />
                  </div>
                </QTContainerBottom>
              ) : (
                <QTContainerBottom>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <NFTCard nft={undefined} />
                  </div>
                </QTContainerBottom>
              )}
            </>
          )}
        </MainContainer>
        {(didGifPlay ||
          matchData?.getMatch == null ||
          matchData?.getMatch?.wagerId == null) && (
          <>
            <Button
              variant="contained"
              className="btn-main"
              sx={{ mt: 2, mb: 4, minHeight: "50px" }}
              onClick={() => navigate("/")}
            >
              Back
            </Button>
          </>
        )}

        {isWager &&
          didGifPlay &&
          winner &&
          winner === publicKey?.toString() && (
            <AlertGlass sx={{ maxWidth: "475px" }} severity="warning">
              The distribution of your prize may take{" "}
              <strong>up to 10 minutes</strong> depending on the network speed.
            </AlertGlass>
          )}
        <br />
      </ContainerStyled>
      <DialogStyled
        open={!didGifPlay && dialogOpen}
        maxWidth={"md"}
        fullWidth={true}
        PaperProps={
          !didGifPlay
            ? {
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }
            : undefined
        }
      >
        <Lottie
          options={{
            animationData:
              winner === publicKey?.toString() ? userWin : opponentWin,
            loop: false,
            autoplay: true,
          }}
          width={"100%"}
          speed={1}
          // height={900}
          // isStopped={!dialogOpen}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                setDidGifPlay(true);
                // setDialogOpen(false);
              },
            },
          ]}
        />
      </DialogStyled>
    </>
  );
}

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}
