import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Transaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getLevel } from "../util";
import moment from "moment";
//mui
import {
  styled,
  Button,
  Dialog,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useRecycleMutation } from "../generated/graphql";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    width: "auto",
  },
}));

const ConnectDiscordContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  padding: "20px",
  borderRadius: "10px",
  color: "#FFF",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  [theme.breakpoints.up("sm")]: {
    padding: "20px",
  },
}));

export default function DialogRecycle(props: {
  open: boolean;
  setOpen: any;
  setDisappear: any;
  nft: any;
}) {
  const { connection } = useConnection();
  const { open, setOpen, setDisappear, nft } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const level = getLevel(nft?.xp || 0);

  const [{ error: recycleError }, recycle] = useRecycleMutation();

  useEffect(() => {
    if (recycleError) toast.error(`ERR: ${recycleError}`);
  }, [recycleError]);

  const wallet = useWallet();

  const onClickRecycle = async () => {
    try {
      setIsLoading(true);
      const mintId = nft?.mintid || "";
      const res = await recycle({
        mintId: mintId,
        user: wallet?.publicKey?.toString() || "",
      });

      console.log("res", res);

      if (res?.data) {
        const { txBONK } = res?.data?.recycle || { txBONK: "" };
        if (txBONK.includes("error")) throw new Error(txBONK);

        const _txBONK = Transaction.from(Buffer.from(txBONK, "base64"));
        await wallet.sendTransaction(_txBONK, connection, {
          skipPreflight: true,
        });

        setOpen(!open);
        toast.success("You sunsetted your QTBot for BONK!");
        setDisappear(true);
      }
    } catch (err) {
      console.error(err);
      toast.error(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateBONK = () => {
    const lv = level?.lvl || 0;
    if (lv === 1) return 100000;
    else if (lv === 2) return 200000;
    else if (lv === 3) return 300000;
    else if (lv === 4) return 400000;
    else if (lv === 5) return 1250000;
    else if (lv === 6) return 2500000;
    else return 0;
  };

  var isSunsetDisabled = false;
  const startDateTime = moment.utc("2023-12-21T19:00:00"); // December 21 at 7:00pm UTC
  const endDateTime = moment.utc("2023-12-23T19:00:00"); // December 23 at 7:00pm UTC

  const isAfterStart = moment().utc().isAfter(startDateTime);
  const isBeforeEnd = moment().utc().isBefore(endDateTime);
  if (isAfterStart && isBeforeEnd) isSunsetDisabled = false;

  return (
    <StyledDialog onClose={() => setOpen(!open)} open={open} fullWidth={true}>
      <ConnectDiscordContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            alignItems: "center",
            mb: 2,
          }}
        >
          <h2 style={{ textDecoration: "underline" }}>
            Sunset {nft?.name ? nft.name : "my QTBot"}?
          </h2>
          <Typography
            sx={{
              pl: 2,
              mr: 2,
            }}
          >
            When sunsetting your QTbot you are trading it for{" "}
            <span style={{ fontWeight: "bold" }}>$BONK</span>. Refer to the
            chart below for amounts. To sunset click the button below and sign
            the transaction!
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  MERRY CHRISTMAS!
                </span>
                Available from
                <span style={{ fontWeight: "bold" }}>
                  December 21, 2023 (2:00pm EST)
                </span>
                <span>
                  to{" "}
                  <span style={{ fontWeight: "bold" }}>
                    December 23, 2023 (2:00pm EST)
                  </span>
                </span>
              </Box>
            </Box>
          </Typography>

          <hr
            style={{
              width: "100%",
              border: "1px solid #eee",
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
              }}
            >
              Lv 1 <span style={{ fontWeight: "bold" }}>100k BONK</span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
              }}
            >
              Lv 2 <span style={{ fontWeight: "bold" }}>200k BONK</span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
              }}
            >
              Lv 3 <span style={{ fontWeight: "bold" }}>300k BONK</span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
                mt: 0,
              }}
            >
              Lv 4 <span style={{ fontWeight: "bold" }}>400k BONK</span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
                mt: 0,
              }}
            >
              Lv 5<span style={{ fontWeight: "bold" }}>1.25m BONK</span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: 1.5,
                mt: 0,
              }}
            >
              Lv 6<span style={{ fontWeight: "bold" }}>2.5m BONK</span>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={nft?.img}
              alt="bot"
              style={{ width: 100, borderRadius: "10px" }}
            />
            <Typography
              sx={{
                ml: 1,
                fontSize: "18px !important",
                fontFamily: "Rubik",
                fontWeight: 550,
              }}
            >
              for
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ ml: 1 }}>
                {/* <Box
                  sx={{
                    color: "#000",
                    backgroundColor: "#FFF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 1,
                    minWidth: "175px",
                    fontSize: "23px !important",
                    fontFamily: "Rubik",
                    fontWeight: 550,
                    mb: 1,
                  }}
                >
                  <img
                    src="/solCoin.png"
                    alt="discord"
                    style={{ marginRight: 10, width: 37 }}
                  />
                  {calculateSOL()}
                </Box> */}

                <Box
                  sx={{
                    color: "#000",
                    backgroundColor: "#FFF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 1,
                    minWidth: "175px",
                    fontSize: "23px !important",
                    fontFamily: "Rubik",
                    fontWeight: 550,
                  }}
                >
                  <img
                    src="/bonk.png"
                    alt="discord"
                    style={{ marginRight: 10, width: 37 }}
                  />
                  {calculateBONK().toLocaleString()}
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            onClick={() => onClickRecycle()}
            className="shine"
            variant="contained"
            disabled={isSunsetDisabled}
            sx={{
              overflow: "hidden",
              p: 5,
              mt: 2,
              width: "240px",
              height: "50px",
              boxShadow:
                "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              textTransform: "uppercase",
              fontSize: 23,
              fontFamily: "Poppins",
              fontWeight: 550,
              background: "linear-gradient(90deg, #f8a904 0%, #ed6521 100%)",
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <>
                <img
                  alt="recycle"
                  src="/sunset.svg"
                  style={{
                    width: 23,
                    height: 23,
                    marginRight: 7,
                    color: isSunsetDisabled ? "#000" : "#FFF",
                  }}
                />
                Sunset
              </>
            )}
          </Button>
        </Box>
      </ConnectDiscordContainer>
    </StyledDialog>
  );
}
