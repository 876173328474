import React from "react";
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeButton = styled(Button)(() => ({
  color: "#FFF",
  background: "linear-gradient(to right, #551ef5 20%, #6a57dd 100%)",
  borderRadius: 5,
  width: 222,
  height: 50,
  fontSize: 18,
  fontWeight: 550,
  marginTop: 15,
}));

export const NotFound = () => {
  const navigation = useNavigate();

  return (
    <React.Fragment>
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            sx={{
              background: "#FFF",
              padding: "20px",
              borderRadius: "10px",
              color: "#FFF",
              backgroundImage:
                "linear-gradient(rgb(33, 212, 253) 0%, rgb(183, 33, 255) 100%)",
            }}
            gutterBottom
          >
            404 not found
          </Typography>
          <br />
          <HomeButton
            onClick={() =>
              navigation({
                pathname: "/",
              })
            }
          >
            Home
          </HomeButton>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
