import { useWallet } from "@solana/wallet-adapter-react";
import { styled } from "@mui/material";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

const WalletMultiButtonStyled = styled(WalletMultiButton)(({ theme }) => ({
  boxShadow:
    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  fontSize: 15,
  fontFamily: "Rubik",
  justifyContent: "center !important",
  width: "70%",
  fontWeight: 550,
  padding: 5,
  background: "linear-gradient(to right, #543ad1 20%, #6a57dd 100%)",
  transition: "all .2s ease-in-out",
  "&:hover": {
    boxShadow: "#ffffffbf 0 0 0 3px, transparent 0 0 0 0",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    fontSize: 23,
    height: 50,
    width: "70%",
  },
}));

const WalletDisconnectButtonStyled = styled(WalletDisconnectButton)(
  ({ theme }) => ({
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    textTransform: "uppercase",
    fontSize: 15,
    fontFamily: "Rubik",
    fontWeight: 550,
    background: "linear-gradient(to right, #551ef5 20%, #6a57dd 100%)",
    transition: "all .2s ease-in-out",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      fontSize: 23,
      height: 50,
      width: 225,
    },
    "&:hover": {
      boxShadow: "#ffffffbf 0 0 0 3px, transparent 0 0 0 0",
    },
  })
);

export const Wallet = () => {
  const { connected } = useWallet();
  return (
    <WalletModalProvider>
      {!connected ? (
        <WalletMultiButtonStyled />
      ) : (
        <WalletDisconnectButtonStyled />
      )}
    </WalletModalProvider>
  );
};
