import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Transaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import Confetti from "react-confetti";

//mui
import {
  styled,
  Button,
  Dialog,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";

import {
  useRedeemMutation,
  useGetRedeemableAmountQuery,
} from "../generated/graphql";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    width: "auto",
  },
}));

const ConnectDiscordContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  padding: "20px",
  borderRadius: "10px",
  color: "#FFF",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  [theme.breakpoints.up("sm")]: {
    padding: "20px",
  },
}));

export default function DialogRedeem(props: { open: boolean; setOpen: any }) {
  const { open, setOpen } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [showConfettiFadeout, setShowConfettiFadeout] =
    useState<boolean>(false);

  const [{ error: redeemError }, redeem] = useRedeemMutation();

  const [{ data: redeemableAmountData }, refetchRedeemable] =
    useGetRedeemableAmountQuery({
      requestPolicy: "network-only",
    });
  const amounts = redeemableAmountData?.getRedeemableAmount;
  const amountRedeemable =
    (amounts?.amountRedeemable ? parseInt(amounts.amountRedeemable) : 0) /
    100000;
  const amountOnHold =
    (amounts?.amountOnHold ? parseInt(amounts.amountOnHold) : 0) / 100000;

  const refetchRedeem = async () => {
    await refetchRedeemable();
  };

  useEffect(() => {
    refetchRedeem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (redeemError) toast.error(`ERR: ${redeemError}`);
  }, [redeemError]);

  const wallet = useWallet();
  const { connection } = useConnection();

  const onClickRedeem = async () => {
    setIsLoading(true);
    try {
      const { data } = await redeem();
      if (!data) return;
      const tx = Transaction.from(Buffer.from(data!.redeem, "base64"));
      await wallet.sendTransaction(tx, connection, {});
      toast.success("You redeemed " + amountRedeemable.toString() + " BONK");
      refetchRedeemable();
      setOpen(!open);
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfettiFadeout(true);
      }, 1500);
      setTimeout(() => {
        setShowConfetti(false);
      }, 4000);
    } catch (err) {
      console.error(err);
      toast.error(`ERR: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showConfetti && (
        <Confetti
          numberOfPieces={300}
          className={showConfettiFadeout ? "hidden" : "visisble"}
        />
      )}

      <StyledDialog onClose={() => setOpen(!open)} open={open} fullWidth={true}>
        <ConnectDiscordContainer>
          {amountOnHold !== 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                <h2 style={{ textDecoration: "underline" }}>
                  Your redeem is processing!
                </h2>
                Your last redeem for {amountOnHold}{" "}
                <span style={{ fontWeight: "bold" }}>$BONK</span> is still
                processing... please wait before redeeming{" "}
                <span style={{ fontWeight: "bold" }}>$BONK</span> again.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  mt: 3,
                  mb: 1.5,
                }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography sx={{ color: "#FFF", fontWeight: "bold" }}>
                    On Hold
                  </Typography>
                  <Box
                    sx={{
                      color: "#000",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 1,
                      minWidth: "175px",
                      fontSize: "23px !important",
                      fontFamily: "Rubik",
                      fontWeight: 550,
                    }}
                  >
                    <img
                      src="/bonk.png"
                      alt="discord"
                      style={{ marginRight: 10, width: 37 }}
                    />
                    {amountOnHold}
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography sx={{ color: "#FFF", fontWeight: "bold" }}>
                    Redeemable
                  </Typography>
                  <Box
                    sx={{
                      color: "#000",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 1,
                      minWidth: "175px",
                      fontSize: "23px !important",
                      fontFamily: "Rubik",
                      fontWeight: 550,
                    }}
                  >
                    <img
                      src="/bonk.png"
                      alt="discord"
                      style={{ marginRight: 10, width: 37 }}
                    />
                    {amountRedeemable}
                  </Box>
                </Box>
              </Box>

              <Button
                onClick={() => {
                  toast.success("Refreshing...");
                  refetchRedeem();
                }}
                variant="contained"
                sx={{
                  mt: 2,
                  width: "240px",
                  height: "50px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  textTransform: "uppercase",
                  fontSize: 23,
                  fontFamily: "Poppins",
                  fontWeight: 550,
                  background:
                    "linear-gradient(to right, #551ef5 20%, #6a57dd 100%)",
                }}
              >
                Refresh
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                <h2 style={{ textDecoration: "underline" }}>
                  How do I get <span style={{ fontWeight: "bold" }}>$BONK</span>
                  ?
                </h2>
                For every battle you play, you'll get: <br />
                500 <span style={{ fontWeight: "bold" }}>$BONK</span> x QTbot
                Level.
                <br />
                Ex. 500 <span style={{ fontWeight: "bold" }}>$BONK</span> x
                QTbot Level 5 = 2500{" "}
                <span style={{ fontWeight: "bold" }}>$BONK</span>
                <br />
                <br />
                Redeem it by clicking the button below and signing the
                transaction!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  mt: 3,
                  mb: 1.5,
                }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography sx={{ color: "#FFF", fontWeight: "bold" }}>
                    Redeemable
                  </Typography>
                  <Box
                    sx={{
                      color: "#000",
                      backgroundColor: "#FFF",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 1,
                      minWidth: "175px",
                      fontSize: "23px !important",
                      fontFamily: "Rubik",
                      fontWeight: 550,
                    }}
                  >
                    <img
                      src="/bonk.png"
                      alt="discord"
                      style={{ marginRight: 10, width: 37 }}
                    />
                    {amountRedeemable}
                  </Box>
                </Box>
              </Box>

              <Button
                onClick={() => onClickRedeem()}
                disabled={amountRedeemable === 0}
                variant="contained"
                sx={{
                  mt: 2,
                  width: "240px",
                  height: "50px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  textTransform: "uppercase",
                  fontSize: 23,
                  fontFamily: "Poppins",
                  fontWeight: 550,
                  background:
                    "linear-gradient(to right, #551ef5 20%, #6a57dd 100%)",
                }}
              >
                {isLoading ? <CircularProgress /> : <>Redeem</>}
              </Button>
            </Box>
          )}
        </ConnectDiscordContainer>
      </StyledDialog>
    </>
  );
}
