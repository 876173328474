import {
  AnchorWallet,
  useAnchorWallet,
  WalletContextState,
} from "@solana/wallet-adapter-react";

import * as anchor from "@project-serum/anchor";
import { Program } from "@project-serum/anchor";
import { IDL } from "../types/challenge";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import toast from "react-hot-toast";

export const joinChallenge = async (
  provider: anchor.Provider,
  wallet: AnchorWallet,
  ctx: WalletContextState,
  challengeKey: PublicKey,
  ata: PublicKey,
  mint: PublicKey,
  verifySig: (sig: string) => Promise<void>
) => {
  const program = new Program(
    IDL as any,
    new anchor.web3.PublicKey(process.env.REACT_APP_SPL_PROGRAMID!),
    provider
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [player, _] = await anchor.web3.PublicKey.findProgramAddress(
    [challengeKey.toBuffer(), wallet.publicKey.toBuffer()],
    program.programId
  );
  if ((await program.account.player.fetchNullable(player)) !== null) {
    await verifySig("sig failed but player token account found");
    return;
  }

  const challenge = await program.account.challenge.fetch(challengeKey);
  const pool = await program.account.pool.fetch(challenge.pool);
  const providerChain = await program.account.provider.fetch(
    challenge.provider
  );
  const instruction = await program.methods
    .join()
    .accounts({
      provider: challenge.provider,
      pool: challenge.pool,
      poolTokenAccount: pool.tokenAccount,
      challenge: challengeKey,
      player: player,
      providerAuthority: providerChain.authority,
      user: wallet.publicKey,
      userTokenAccount: ata,
      payer: wallet.publicKey,
      mint: mint,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: anchor.web3.SystemProgram.programId,
    })
    .instruction();
  const blockhash = (await provider.connection.getRecentBlockhash()).blockhash;
  const tx = await transferWrappedSolInstruction(
    provider.connection,
    ctx,
    challenge.entryFee.toNumber()
  );
  tx.add(instruction);
  tx.recentBlockhash = blockhash;
  tx.feePayer = wallet.publicKey!;

  let shouldThrow = false;

  const sig = await toast.promise(
    Promise.resolve(
      (async () => {
        try {
          return ctx.sendTransaction(tx, provider.connection, {
            maxRetries: 5,
          });
        } catch (e) {
          shouldThrow = true;
          throw e;
        }
      })()
    ),
    {
      loading: "Joining challenge...",
      success: "Joined challenge!",
      error: "Failed to join challenge!",
    }
  );
  if (shouldThrow) {
    throw new Error("Failed to join challenge!");
  }

  await toast.promise(
    Promise.resolve(
      (async () => {
        try {
          return provider.connection.confirmTransaction(sig);
        } catch (e) {
          shouldThrow = true;
          throw e;
        }
      })()
    ),
    {
      loading: "Confirming transaction...",
      success: "Transaction confirmed!",
      error: "Failed to confirm transaction!",
    }
  );
  if (shouldThrow) {
    throw new Error("Failed to join challenge!");
  }
  await verifySig(sig);
};

export const useProvider = () => {
  const wallet = useAnchorWallet();
  const connection = new anchor.web3.Connection(
    process.env.REACT_APP_SOLANA_RPC || anchor.web3.clusterApiUrl("devnet")
  );

  const provider = new anchor.Provider(
    connection,
    wallet!,
    anchor.Provider.defaultOptions()
  );
  return { provider, connection };
};
export const transferWrappedSol = async (
  connection: anchor.web3.Connection,
  wallet: WalletContextState,
  amount: number
) => {
  const instruction = await transferWrappedSolInstruction(
    connection,
    wallet,
    amount
  );
  if (instruction) {
    await wallet.sendTransaction(instruction, connection);
  }
};
export const transferWrappedSolInstruction = async (
  connection: anchor.web3.Connection,
  wallet: WalletContextState,
  amount: number
) => {
  const ata = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    NATIVE_MINT,
    wallet.publicKey!
  );
  let actualAmount = 0;
  const tx = new Transaction();
  try {
    const tokenAmount = await connection.getTokenAccountBalance(ata);
    actualAmount = parseFloat(tokenAmount.value.amount);
    if (actualAmount >= amount) {
      return tx;
    }
  } catch (err) {
    tx.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        NATIVE_MINT,
        ata,
        wallet.publicKey!,
        wallet.publicKey!
      )
    );
  }

  tx.add(
    SystemProgram.transfer({
      fromPubkey: wallet.publicKey!,
      toPubkey: ata,
      lamports: amount - actualAmount,
    }),
    // Sync Native instruction. @solana/spl-token will release it soon. Here use the raw instruction temporally.
    new TransactionInstruction({
      keys: [
        {
          pubkey: ata,
          isSigner: false,
          isWritable: true,
        },
      ],
      data: Buffer.from(new Uint8Array([17])),
      programId: TOKEN_PROGRAM_ID,
    })
  );
  const blockhash = (await connection.getRecentBlockhash()).blockhash;
  tx.recentBlockhash = blockhash;
  tx.feePayer = wallet.publicKey!;

  return tx;
};

// interface Player {
//   key: PublicKey;
//   auth: PublicKey;
//   amount: number;
// }

// const isPlayerTokenAccountFound = (address: string, players: Player[]) => {
//   const player = players.find((p) => p.auth.toString() === address);
//   return !!player;
// };
