import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { Challenges } from "./Challenges";

//mui
import {
  Container,
  Grid,
  styled,
  Toolbar,
  Button,
  Box,
  Link,
  List,
  ListItem,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

//components
import { NFTContainer } from "../components/NFTContainer";
import { Wallet } from "../components/Wallet";
import DialogRedeem from "../components/DialogRedeem";
import DialogDiscord from "../components/DialogDiscord";

import { useBattleJoin } from "../solanya/useJoin";
import { NATIVE_MINT } from "@solana/spl-token";

import {
  Nft,
  useGetUserQuery,
  useQueueMatchMutation,
  useUpdateDiscordMutation,
  useGetRedeemableAmountQuery,
} from "../generated/graphql";

const DrawerBox = styled(Box)(({ theme }) => ({
  "& button": {
    width: "100%",
    marginRight: "0px",
  },
}));

const NFTSlot = styled("div")(({ theme }) => ({
  height: 408,
  [theme.breakpoints.up("sm")]: {
    height: 441,
  },
  [theme.breakpoints.up("md")]: {
    height: 472,
  },
  maxWidth: 275,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  borderRadius: 10,
  overflow: "hidden",
  color: "#000",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(6px)",
  justifyContent: "center",
  "& img": {
    maxWidth: "100%",
    padding: 15,
    [theme.breakpoints.up("md")]: {
      padding: 50,
    },
  },
}));

const NoBotzWarning = styled("div")(({ theme }) => ({
  width: "450px",
  borderRadius: "15px",
  margin: "0 auto",
  padding: "25px 50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "200px",
  color: "#FFFFFF",
  fontSize: "30px",
  fontWeight: "600",
  textAlign: "center",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  "& .MuiButton-root": {
    marginTop: "25px",
    marginBottom: "15px",
    background: "linear-gradient(to right, #551ef550 20%, #6a57dd50 100%)",
    width: 175,
    fontSize: 18,
    fontWeight: 550,
  },
  "& a": {
    textDecoration: "none",
  },
}));

export default function Home() {
  const query = useQuery();
  const navigation = useNavigate();

  const [openDiscord, setOpenDiscord] = useState(false);
  const [openRedeem, setOpenRedeem] = useState(false);
  const [username, setUsername] = useState<string | null>("");
  const [discord, setDiscord] = useState<string | null>("");
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showChallenges, setShowChallenges] = useState<boolean>(false);
  const [selectedNft, setSelectedNft] = useState<any>(null);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [{ data: userData, error: userDataError }] = useGetUserQuery({
    requestPolicy: "network-only",
  });

  const [{ data: redeemableAmountData, error: redeemableAmountError }] =
    useGetRedeemableAmountQuery({
      requestPolicy: "network-only",
    });
  const amounts = redeemableAmountData?.getRedeemableAmount;
  const amountRedeemable =
    (amounts?.amountRedeemable ? parseInt(amounts.amountRedeemable) : 0) /
    100000;

  useEffect(() => {
    if (redeemableAmountError) toast.error(`ERR: ${redeemableAmountError}`);
  }, [redeemableAmountError]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ error: createMatchError }, queueMatch] = useQueueMatchMutation();

  const [{ error: discordMutationError }, updateDiscord] =
    useUpdateDiscordMutation();

  const {
    isLoading: isMatchLoading,
    canJoin: canJoinMatch,
    join: joinMatch,
  } = useBattleJoin(NATIVE_MINT.toString(), 31000000, (id) => {
    return navigation(`/match/${id}`);
  });

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    if (userDataError) toast.error(`ERR: ${userDataError}`);
  }, [userDataError]);

  useEffect(() => {
    if (discordMutationError) toast.error(`ERR: ${discordMutationError}`);
  }, [discordMutationError]);

  useEffect(() => {
    if (createMatchError) toast.error(`ERR: ${createMatchError}`);
  }, [createMatchError]);

  useEffect(() => {
    if (userData?.getUser) {
      setUsername(userData?.getUser?.discordUsername || "");
      setDiscord(userData?.getUser?.discord || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (query[0] !== null && userData?.getUser?.discord === null) {
      fetchUserid(query[0]).then((discord) => {
        if (discord) setUsername(discord?.username);
        updateDiscord({
          socials: { discord: discord.id, discordUserName: discord.username },
        }).then(() => {
          setOpenDiscord(false);
        });
      });
    }
  }, [query, updateDiscord, userData?.getUser?.discord]);

  const fetchUserid = (access_token: string) =>
    axios
      .get(`https://discordapp.com/api/users/@me`, {
        headers: { authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setUsername(res?.data?.username);
        return { id: res.data.id, username: res.data.username };
      });

  function useQuery() {
    const { hash } = useLocation();

    return React.useMemo(() => {
      const fragment = new URLSearchParams(hash);
      return [fragment.get("access_token"), fragment.get("token_type")];
    }, [hash]);
  }

  const nfts = userData?.getUsersNfts;

  if (nfts && nfts?.length <= 4) {
    for (var i = 0; i < nfts.length; i++) {
      if (nfts?.length < 4) {
        nfts?.push({
          __typename: undefined,
          mintid: "",
          name: "slot",
          img: "",
          xp: 0,
          gamesPlayedToday: 0,
        });
      }
    }
  }

  function Topbar() {
    return (
      <Toolbar sx={{ dispaly: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "350px", mr: 2 }}>
          <img
            src={"./qtbotz_logo.svg"}
            alt={"QT BATTLEZ"}
            style={{
              objectFit: "cover",
              width: "350px",
            }}
          />
        </Box>

        {isSm ? (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              className="btn-main"
              sx={{
                mr: 1,
                justifyContent: "space-between",
              }}
              variant="contained"
              onClick={() => setOpenRedeem(!openRedeem)}
            >
              <img
                src="/bonk.png"
                alt="discord"
                style={{ marginRight: 10, width: 37 }}
              />
              <span>{amountRedeemable}</span>
            </Button>
            <Button
              className="btn-main"
              sx={{ mr: 1 }}
              variant="contained"
              onClick={() => setOpenDiscord(!openDiscord)}
            >
              <img
                src="/discord_logo.png"
                alt="discord"
                style={{ marginRight: 10, width: 20 }}
              />
              <span>Discord</span>
            </Button>

            <Wallet />
          </Box>
        ) : (
          <IconButton
            onClick={() => setShowDrawer(!showDrawer)}
            className="btn-main"
            sx={{
              minWidth: "50px !important",
              width: "50px",
            }}
          >
            <MenuIcon sx={{ color: "#FFF" }} />
          </IconButton>
        )}
      </Toolbar>
    );
  }

  return (
    <Box>
      <Topbar />

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          overflow: "auto",
          marginTop: "8%",
          marginBottom: "4%",
          maxWidth: "none !important",
        }}
      >
        {showChallenges ? (
          <Grid container alignItems="center" justifyContent="center">
            <Challenges
              setShowChallenges={setShowChallenges}
              nft={selectedNft}
            />
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center">
            {userData?.getUsersNfts?.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <NoBotzWarning>
                  You don't have any QTBotz yet!
                  <Link
                    color="inherit"
                    target="_blank"
                    href="https://qtbotz.com/"
                  >
                    <Button variant="contained">QTBotz.com</Button>
                  </Link>
                </NoBotzWarning>
              </Box>
            )}
            <Grid container spacing={3} sx={{ maxWidth: 1200 }}>
              {((userData?.getUsersNfts || []) as Nft[])
                .sort((a, b) => (a!.xp > b!.xp ? -1 : 1))
                .map((nft, i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      xs={6}
                      md={3}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {nft.name === "slot" ? (
                        <NFTSlot>
                          <img src="/qtbotz_logo.svg" alt="solCoin" />
                        </NFTSlot>
                      ) : (
                        <NFTContainer
                          nft={nft}
                          isMatchLoading={isMatchLoading}
                          canJoinMatch={canJoinMatch}
                          joinMatch={joinMatch}
                          showChallenges={showChallenges}
                          setShowChallenges={setShowChallenges}
                          setSelectedNft={setSelectedNft}
                        />
                      )}
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Container>

      <Drawer
        anchor={"left"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <DrawerBox
          role="presentation"
          sx={{
            width: 250,
            backgroundColor: "#a292ff",
            height: "100vh",
          }}
        >
          <List>
            <ListItem sx={{ mb: 2 }}>
              <img src={"./qtbattlez_logo.svg"} alt={"QT BATTLEZ"} />
            </ListItem>
            <ListItem>
              <Button
                className="btn-main"
                variant="contained"
                onClick={() => setOpenRedeem(!openRedeem)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src="/bonk.png"
                  alt="discord"
                  style={{ marginRight: 10, width: 37 }}
                />
                <span>{amountRedeemable}</span>
              </Button>
            </ListItem>

            <ListItem>
              <Button
                className="btn-main"
                variant="contained"
                onClick={() => setOpenDiscord(!openDiscord)}
              >
                <img
                  src="/discord_logo.png"
                  alt="discord"
                  style={{ marginRight: 10, width: 20 }}
                />
                <span>Discord</span>
              </Button>
            </ListItem>

            <ListItem>
              <Wallet />
            </ListItem>
          </List>
        </DrawerBox>
      </Drawer>

      <DialogRedeem
        setOpen={() => setOpenRedeem(!openRedeem)}
        open={openRedeem}
      />

      <DialogDiscord
        setOpen={() => setOpenDiscord(!openDiscord)}
        open={openDiscord}
        discord={discord}
        username={username}
      />
    </Box>
  );
}
