import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NFTCard } from "../components/NFTCard";
import { styled, Button, Box } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { getLevel } from "../util";
import {
  Nft,
  useGetUserQuery,
  useQueueMatchMutation,
} from "../generated/graphql";
import DialogRecycle from "../components/DialogRecycle";
// import { useBattleJoin } from "../solanya/useJoin";
// import { getLevel } from "../util";

interface NFTCointainerProps {
  nft?: Nft;
  isMatchLoading?: boolean;
  canJoinMatch?: boolean;
  joinMatch?: (nft: string) => Promise<void>;
  showChallenges?: boolean;
  setShowChallenges?: any;
  setSelectedNft?: any;
}

const NFT_CONTAINER = styled("div")(({ theme }) => ({
  maxWidth: 275,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  borderRadius: 10,
  overflow: "hidden",
  color: "#000",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
}));

const ActionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: 20,
  marginTop: 15,
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  borderRadius: 10,
  overflow: "hidden",
  width: "100%",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  [theme.breakpoints.up("sm")]: {
    paddingBottom: 20,
    marginTop: 20,
  },
}));

// const DuelContainer = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   "& .prizeContainer": {
//     background:
//       "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
//     backdropFilter: "blur(20px)",
//     width: "100%",
//     color: "#FFF",
//     display: "flex",
//     alignItems: "left",
//     justifyContent: "center",
//     flexDirection: "column",
//     fontWeight: 500,
//     fontSize: 10,
//     [theme.breakpoints.up("sm")]: {
//       fontSize: 12,
//     },
//     "& div": {
//       whiteSpace: "nowrap",
//       fontSize: 12,
//       marginBottom: 2,
//       marginTop: 3,
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       textAlign: "right",
//       [theme.breakpoints.up("sm")]: {
//         fontSize: 16,
//       },
//     },
//     "& span": {
//       whiteSpace: "nowrap",
//       textAlign: "right",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//     },
//     "& img": {
//       marginLeft: 5,
//       height: 18,
//       width: 18,
//     },
//   },
//   "& .prize": {
//     display: "flex",
//     alignItems: "center",
//     fontSize: 16,
//     [theme.breakpoints.up("sm")]: {
//       fontSize: 24,
//     },
//   },
// }));

const BattleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
  flexDirection: "column",
}));

const Icon = styled("img")(({ theme }) => ({
  height: 25,
  width: 25,
  marginRight: 10,

  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background:
      "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
    backdropFilter: "blur(20px)",
    color: "#FFF",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: 20,
    borderRadius: 10,
  },
}));

export const NFTContainer: React.FC<NFTCointainerProps> = ({
  nft,
  isMatchLoading,
  showChallenges,
  setShowChallenges,
  setSelectedNft,
}) => {
  const navigation = useNavigate();
  const [openRecycle, setOpenRecycle] = useState(false);
  const [disappear, setDisappear] = useState(false);

  const [{ data: userData }] = useGetUserQuery({
    requestPolicy: "network-only",
  });

  const [{ error: createMatchError }, queueMatch] = useQueueMatchMutation();

  useEffect(() => {
    if (createMatchError) toast.error(`ERR: ${createMatchError}`);
  }, [createMatchError]);

  const gamesPlayedToday = nft?.gamesPlayedToday || 0;
  const MAX = userData?.getGame.maxBattles || 5;

  const { lvl } = getLevel(nft?.xp || 0);

  return (
    <NFT_CONTAINER
      className={disappear ? "fade-out" : ""}
      style={
        lvl === 6
          ? {
              background:
                "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
            }
          : {}
      }
    >
      <NFTCard nft={nft} home={true} />
      <ActionsContainer>
        <BattleContainer>
          <LightTooltip
            arrow
            title={
              <span>
                QTBotz can level up by doing free battles. Each QTBot can do 5
                battles a day and they earn <strong>2 EXP</strong> for each win
                and <strong>BONK</strong> for each match played. However, for
                QTBotz below level 5, only 1 EXP is earned for losing.
              </span>
            }
          >
            <Box sx={{ width: "100%", mb: 2 }}>
              <Button
                className="btn-main"
                sx={{
                  width: "100%",
                  flex: 1,
                  minWidth: "0px !important",
                }}
                variant="contained"
                disabled={gamesPlayedToday >= MAX || isMatchLoading}
                onClick={() => {
                  queueMatch({ mintid: nft?.mintid || "" }).then((queue) => {
                    return navigation(`/match/${queue.data?.queueMatch}`);
                  });
                }}
              >
                <Icon src="/battleIcon.svg" alt="solCoin" />
                {MAX - gamesPlayedToday} of {MAX}
              </Button>
            </Box>
          </LightTooltip>
          {/* <LightTooltip
            arrow
            title={
              <span>
                Challenge another player to a wager match. Players wager BONK
                token and the winner of the battle wins a prize! QTBotz levels
                don't matter in wager matches... the odds are always 50/50.
              </span>
            }
          >
            <Box sx={{ width: "100%", mb: 2 }}>
              <Button
                variant="contained"
                className="btn-main "
                onClick={() => {
                  setSelectedNft(nft);
                  setShowChallenges(!showChallenges);
                }}
                size="small"
                sx={{
                  width: "100%",
                  minWidth: "0px !important",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <Icon
                  src="/bonk.png"
                  alt="bonk"
                  style={{
                    marginRight: 5,
                    width: 28,
                    height: 28,
                  }}
                />
                Wager
              </Button>
            </Box>
          </LightTooltip> */}

          <Box sx={{ width: "100%" }}>
            <Button
              onClick={() => {
                setOpenRecycle(true);
              }}
              className="btn-main shine"
              sx={{
                width: "100%",
                flex: 1,
                minWidth: "0px !important",
                background: "linear-gradient(90deg, #f8a904 0%, #ed6521 100%)",
              }}
              variant="contained"
            >
              <Icon
                src="/sunset.svg"
                alt="recycle"
                style={{
                  marginTop: "3px",
                }}
              />
              Sunset
            </Button>
          </Box>
        </BattleContainer>
      </ActionsContainer>

      <DialogRecycle
        setOpen={() => setOpenRecycle(!openRecycle)}
        open={openRecycle}
        setDisappear={setDisappear}
        nft={nft}
      />
    </NFT_CONTAINER>
  );
};
