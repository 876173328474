import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
// import { ChallengeView } from "../components/ChallengeView";
import { useWallet } from "@solana/wallet-adapter-react";

//mui
import {
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  useGetChallengesQuery,
  useCreateChallengeMutation,
  useGetChallengeHistoryQuery,
} from "../generated/graphql";

export const Challenges: React.FC<{
  setShowChallenges;
  nft;
}> = ({ setShowChallenges, nft }) => {
  const { publicKey } = useWallet();

  // const [challengeId, setChallengeId] = useState<number>(0);
  const [viewPastWagers, setViewPastWagers] = useState<boolean>(false);

  const [{ data: challengesData, error: challengesDataError }, getChallenges] =
    useGetChallengesQuery({
      requestPolicy: "network-only",
    });
  const challengeList = challengesData?.getChallenges.filter(
    (challenge) => challenge?.state === "CREATED"
  );

  const [{ data: challengesHistoryData, error: challengesHistoryDataError }] =
    useGetChallengeHistoryQuery({
      requestPolicy: "network-only",
      variables: {
        wallet: publicKey?.toString() ? publicKey?.toString() : "",
      },
    });

  useEffect(() => {
    if (challengesHistoryDataError)
      toast.error(`ERR: ${challengesHistoryDataError}`);
  }, [challengesHistoryDataError]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ error: createChallengeError }, createChallenge] =
    useCreateChallengeMutation();

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    if (challengesDataError) toast.error(`ERR: ${challengesDataError}`);
  }, [challengesDataError]);

  useEffect(() => {
    if (createChallengeError) toast.error(`ERR: ${createChallengeError}`);
  }, [createChallengeError]);

  const createdChallenges =
    challengeList &&
    challengeList.filter((challenge) => challenge?.state === "CREATED");
  const canCreateChallenge = createdChallenges && createdChallenges?.length < 4;

  return (
    <>
      <Box sx={{ position: "absolute", marginLeft: "-725px" }}>
        <img
          className="dance"
          src="/nft-bot.png"
          alt="nft-bot"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        ></img>
      </Box>

      <Grid container alignItems="center" justifyContent="center">
        {
          // challengeId !== 0 ? (
          //   <ChallengeView
          //     challengeId={challengeId}
          //     setChallengeId={setChallengeId}
          //     nft={nft}
          //   />
          // ) :

          viewPastWagers ? (
            <Box
              className="glass-container"
              sx={{
                width: "100%",
                maxWidth: "400px",
                position: "relative",
              }}
            >
              <Typography
                variant={"h4"}
                sx={{ mb: 2, display: "flex", justifyContent: "center" }}
              >
                Past Wagers
              </Typography>

              <Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {challengesHistoryData?.getChallengeHistory?.length === 0 ? (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No past challenges
                    </div>
                  ) : (
                    challengesHistoryData?.getChallengeHistory
                      ?.filter((challenge) => challenge?.state === "RESOLVED")
                      .map((challenge) => (
                        <Button
                          className="btn-main"
                          fullWidth
                          key={challenge?.id}
                          // onClick={() => {
                          //   if (challenge?.id) setChallengeId(challenge.id);
                          // }}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            margin: 1,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          <span>#{challenge?.id}</span>
                        </Button>
                      ))
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Button
                    onClick={() => setViewPastWagers(!viewPastWagers)}
                    className="btn-main"
                    fullWidth
                    variant="contained"
                    sx={{ m: 1 }}
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className="glass-container"
              sx={{
                width: "100%",
                maxWidth: "400px",
                position: "relative",
              }}
            >
              <IconButton
                size="small"
                onClick={() => setShowChallenges(false)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "-10px",
                  left: "-10px",
                  border: "1px solid #6b727e",
                  background: `linear-gradient(
                  108.46deg,
                  rgba(255, 255, 255, 0.264) 0%,
                  rgba(255, 255, 255, 0.066) 100%
                )`,
                  color: "#FFF",
                }}
              >
                <ArrowBackIcon />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => {
                  toast.success("Fetching challenges!");
                  getChallenges();
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  border: "1px solid #6b727e",
                  background: `linear-gradient(
                  108.46deg,
                  rgba(255, 255, 255, 0.264) 0%,
                  rgba(255, 255, 255, 0.066) 100%
                )`,
                  color: "#FFF",
                }}
              >
                <RefreshIcon />
              </IconButton>

              <Typography
                variant={"h4"}
                sx={{ mb: 2, display: "flex", justifyContent: "center" }}
              >
                PvP Wagers
              </Typography>

              <Typography
                variant={"h6"}
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column ",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Entry Fee: 1000{" "}
                  <img
                    src="/bonk.png"
                    alt="discord"
                    style={{ marginLeft: 5, width: 20, height: 20 }}
                  />
                </span>

                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Winnings: 1800
                  <img
                    src="/bonk.png"
                    alt="discord"
                    style={{ marginLeft: 5, width: 20, height: 20 }}
                  />
                </span>
              </Typography>

              <Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant={"h6"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column ",
                    }}
                  >
                    Rooms
                  </Typography>
                  {challengeList?.length === 0 ? (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No rooms available
                    </div>
                  ) : (
                    challengeList?.map((challenge) => (
                      <Button
                        className="btn-main"
                        fullWidth
                        key={challenge?.id}
                        // onClick={() => {
                        //   if (challenge?.id) setChallengeId(challenge.id);
                        // }}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          margin: 1,
                          textAlign: "center",
                          color: "#FFF",
                        }}
                      >
                        <span>#{challenge?.id}</span>
                      </Button>
                    ))
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Button
                    disabled={!canCreateChallenge}
                    onClick={async () => await createChallenge()}
                    className="btn-main"
                    fullWidth
                    variant="contained"
                    sx={{ m: 1, backgroundColor: "#3eb718" }}
                  >
                    Create Room
                  </Button>

                  <Button
                    onClick={() => setViewPastWagers(!viewPastWagers)}
                    className="btn-main"
                    fullWidth
                    variant="contained"
                    sx={{ m: 1 }}
                  >
                    View Past Wagers
                  </Button>
                </Box>
              </Box>
            </Box>
          )
        }
      </Grid>
    </>
  );
};
