import { styled, Button, Dialog, Box } from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
  },
}));

const ConnectDiscordContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  maxWidth: "500px",
  padding: "20px",
  borderRadius: "10px",
  color: "#FFF",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
  backdropFilter: "blur(20px)",
  [theme.breakpoints.up("sm")]: {
    height: "300px",
    padding: "20px",
  },
}));

export default function DialogDiscord(props: {
  open: boolean;
  setOpen: any;
  discord: string | null;
  username: string | null;
}) {
  const { open, setOpen, discord, username } = props;

  return (
    <StyledDialog onClose={() => setOpen(!open)} open={open} fullWidth={true}>
      <ConnectDiscordContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              mb: 3,
              display: "flex",
              flexDirection: "column",
              padding: 1,
              minWidth: "200px",
              fontSize: "23px !important",

              fontFamily: "Rubik",
              fontWeight: 550,
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              background:
                "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
            }}
          >
            <Box>Username: {username || "N/A"}</Box>
            <Box>Discord ID: {discord || "N/A"}</Box>
          </Box>

          {/* {discord && (
            <Box
              sx={{
                mt: "15px",
                fontWeight: "bold",
                border: "#fff 1px solid",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <span>Username: {username}</span>
              <br />
              <span>Discord ID: {discord}</span>
            </Box>
          )} */}

          <Box
            sx={{ mb: 2, width: "80%", textAlign: "center", color: "white" }}
          >
            Connect your wallet to discord so that the QTreferee can ref your
            battles.
            <br />
            This step is only needed once!
          </Box>

          <Button
            variant="contained"
            target="_blank"
            href="https://discord.com/api/oauth2/authorize?client_id=918924884137091083&redirect_uri=https%3A%2F%2Fplay.qtbotz.com&response_type=token&scope=identify"
            sx={{
              boxShadow:
                "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
              textTransform: "uppercase",
              fontSize: 23,
              fontFamily: "Rubik",
              fontWeight: 550,
              background:
                "linear-gradient(to right, #551ef5 20%, #6a57dd 100%)",
            }}
          >
            <img
              src="/discord_logo.png"
              alt="discord"
              style={{ marginRight: 10, width: 20 }}
            />
            Connect
          </Button>
        </Box>
      </ConnectDiscordContainer>
    </StyledDialog>
  );
}
