import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Router } from "./router";
import { GqlProvider } from "./GqlProvider";
import { Toaster } from "react-hot-toast";
import { Typography, Link } from "@mui/material";
import { useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  PhantomWalletAdapter,
  // SolletWalletAdapter,
  SolflareWalletAdapter,
  // SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";

const mdTheme = createTheme({
  typography: {
    fontFamily: "Rubik",
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.7)",
        },
      },
    },
  },
});

function DashboardContent() {
  const network = process.env.REACT_APP_NET! as WalletAdapterNetwork;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      // new SolletWalletAdapter({ network }),
      // new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{
          fontWeight: "semibold",
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          paddingBottom: 0.5,
          paddingTop: 0.5,
          color: "#FFF",
          backgroundColor: "#4b4584eb",
        }}
        {...props}
      >
        {"Copyright © "}
        <Link
          color="inherit"
          target="_blank"
          href="https://www.ludex.gg"
          sx={{ mr: 1 }}
        >
          Ludex Technologies Inc.
        </Link>
        {new Date().getFullYear()}
      </Typography>
    );
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <ThemeProvider theme={mdTheme}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              height: "100vh",
              overflow: "auto",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage:
                "linear-gradient(rgb(25 0 170 / 50%), rgb(165 157 255 / 50%)),url(/background.gif)",
            }}
          >
            <GqlProvider>
              <Router />
            </GqlProvider>
            <Copyright />
            <Toaster position={"top-center"} />
          </Box>
        </ThemeProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
