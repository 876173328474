import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BridgeTransaction = {
  __typename?: 'BridgeTransaction';
  id: Scalars['Int'];
  status: BridgeTransactionStatus;
  uuid: Scalars['String'];
};

export enum BridgeTransactionStatus {
  Created = 'CREATED',
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type Challenge = {
  __typename?: 'Challenge';
  blockchainAddress: Scalars['String'];
  id: Scalars['Int'];
  payoutId: Scalars['Int'];
  players?: Maybe<Array<Maybe<Player>>>;
  state: Scalars['String'];
};

export enum CurrencyType {
  Lux = 'LUX',
  Sol = 'SOL'
}

export type Game = {
  __typename?: 'Game';
  maxBattles: Scalars['Int'];
  resetTime: Scalars['String'];
  tournamentResetTime?: Maybe<Scalars['String']>;
};

export type IInsertTransaction = {
  address: Scalars['String'];
  amount: Scalars['Float'];
  createdAt: Scalars['String'];
  currencyType: CurrencyType;
  memo: Scalars['String'];
  transactionId: Scalars['String'];
};

export type IUpdateSocials = {
  discord?: InputMaybe<Scalars['String']>;
  discordUserName?: InputMaybe<Scalars['String']>;
  twitch?: InputMaybe<Scalars['String']>;
};

export type Match = {
  __typename?: 'Match';
  battleBots: Array<Maybe<Nft>>;
  isWager: Scalars['Boolean'];
  wagerId?: Maybe<Scalars['String']>;
  winner?: Maybe<Scalars['String']>;
};

export type MatchStats = {
  __typename?: 'MatchStats';
  blockchainAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  endedAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  players: Array<Maybe<Nft>>;
  wagerId?: Maybe<Scalars['String']>;
  winner?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addRunScore: Scalars['Int'];
  backoutWager: Scalars['Boolean'];
  cancelWager: Scalars['Int'];
  createBridgeTransaction: BridgeTransaction;
  createChallenge: Challenge;
  createRunWager: Scalars['Int'];
  createSeason: Scalars['Int'];
  createTournament: Scalars['Int'];
  createWager: Scalars['Int'];
  createWallet: Scalars['String'];
  insertReferal: User;
  insertTransactions: Array<Maybe<Transaction>>;
  insertXmasScore: Scalars['Int'];
  joinGassedWager: Scalars['Int'];
  queueMatch: Scalars['Int'];
  recycle: RecycleTransactions;
  redeem: Scalars['String'];
  redeemSolToEth: Scalars['Int'];
  resolveWager: Scalars['Int'];
  startChallenge: Scalars['String'];
  updateSocials: User;
  verifyJoin: Scalars['Boolean'];
  verifySignature: Scalars['Boolean'];
};


export type MutationAddRunScoreArgs = {
  id: Scalars['Int'];
  score: Scalars['String'];
};


export type MutationBackoutWagerArgs = {
  id: Scalars['Int'];
};


export type MutationCancelWagerArgs = {
  id: Scalars['Int'];
};


export type MutationCreateBridgeTransactionArgs = {
  chain: Scalars['String'];
  publicKey: Scalars['String'];
  sequenceNumber: Scalars['String'];
};


export type MutationCreateRunWagerArgs = {
  mint: Scalars['String'];
};


export type MutationCreateSeasonArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type MutationCreateTournamentArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type MutationCreateWagerArgs = {
  mint: Scalars['String'];
};


export type MutationInsertReferalArgs = {
  referal: Scalars['String'];
};


export type MutationInsertTransactionsArgs = {
  transactions: Array<InputMaybe<IInsertTransaction>>;
};


export type MutationInsertXmasScoreArgs = {
  score: Scalars['String'];
};


export type MutationJoinGassedWagerArgs = {
  challengeId: Scalars['Int'];
  publicKey: Scalars['String'];
};


export type MutationQueueMatchArgs = {
  mint: Scalars['String'];
};


export type MutationRecycleArgs = {
  mintId: Scalars['String'];
  user: Scalars['String'];
};


export type MutationRedeemSolToEthArgs = {
  amount: Scalars['Float'];
  ethAddress: Scalars['String'];
  publicKey: Scalars['String'];
};


export type MutationResolveWagerArgs = {
  id: Scalars['Int'];
};


export type MutationStartChallengeArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateSocialsArgs = {
  socials: IUpdateSocials;
};


export type MutationVerifySignatureArgs = {
  id: Scalars['Int'];
  signature: Scalars['String'];
};

export type NftTournamentPlayer = {
  __typename?: 'NFTTournamentPlayer';
  battles: Scalars['Int'];
  discordUsername?: Maybe<Scalars['String']>;
  mintid: Scalars['String'];
  owner: Scalars['String'];
  qtBotName: Scalars['String'];
  wins: Scalars['Int'];
};

export type Nft = {
  __typename?: 'Nft';
  gamesPlayedToday: Scalars['Int'];
  img: Scalars['String'];
  mintid: Scalars['String'];
  name: Scalars['String'];
  owner: Scalars['String'];
  xp: Scalars['Int'];
};

export type Player = {
  __typename?: 'Player';
  amountWonDecimal: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAllUsers: Array<Maybe<User>>;
  getBridgeTransactions?: Maybe<Array<BridgeTransaction>>;
  getChallenge: Challenge;
  getChallengeHistory: Array<Maybe<Challenge>>;
  getChallenges: Array<Maybe<Challenge>>;
  getGame: Game;
  getMatch: Match;
  getMatches: Array<Maybe<MatchStats>>;
  getRedeemableAmount: RedeemableAmounts;
  getRunMatch: RunMatch;
  getSeason: Array<Maybe<NftTournamentPlayer>>;
  getTounament: Array<Maybe<NftTournamentPlayer>>;
  getUnresolvedMatch: Array<Maybe<SearchedMatch>>;
  getUser?: Maybe<User>;
  getUsersNfts: Array<Maybe<Nft>>;
};


export type QueryGetBridgeTransactionsArgs = {
  publicKey: Scalars['String'];
};


export type QueryGetChallengeArgs = {
  id: Scalars['Int'];
};


export type QueryGetChallengeHistoryArgs = {
  wallet: Scalars['String'];
};


export type QueryGetMatchArgs = {
  id: Scalars['Int'];
};


export type QueryGetMatchesArgs = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
};


export type QueryGetRunMatchArgs = {
  id: Scalars['Int'];
};


export type QueryGetSeasonArgs = {
  now: Scalars['String'];
};


export type QueryGetTounamentArgs = {
  now: Scalars['String'];
};

export type RecycleTransactions = {
  __typename?: 'RecycleTransactions';
  txBONK: Scalars['String'];
};

export type RedeemableAmounts = {
  __typename?: 'RedeemableAmounts';
  amountOnHold: Scalars['String'];
  amountRedeemable: Scalars['String'];
};

export type RunMatch = {
  __typename?: 'RunMatch';
  created_at: Scalars['String'];
  ended_at?: Maybe<Scalars['String']>;
  firstPlayerAddress?: Maybe<Scalars['String']>;
  firstPlayerScore?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  playersJoined: Array<Maybe<Scalars['String']>>;
  secondPlayerAddress?: Maybe<Scalars['String']>;
  secondPlayerScore?: Maybe<Scalars['Int']>;
  wagerId?: Maybe<Scalars['String']>;
  wagerRefId: Scalars['Int'];
  wagered: Scalars['Boolean'];
  winner?: Maybe<Scalars['String']>;
};

export type SearchedMatch = {
  __typename?: 'SearchedMatch';
  id: Scalars['Int'];
  isWager: Scalars['Boolean'];
  players: Array<Maybe<User>>;
  wagerId?: Maybe<Scalars['Int']>;
  winner?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  address: Scalars['String'];
  amount: Scalars['Float'];
  createdAt: Scalars['String'];
  currencyType: CurrencyType;
  memo: Scalars['String'];
  transactionId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  address: Scalars['String'];
  discord?: Maybe<Scalars['String']>;
  discordUsername?: Maybe<Scalars['String']>;
  twitch?: Maybe<Scalars['String']>;
  whitelisted?: Maybe<Scalars['Boolean']>;
};

export type Whitelist = {
  __typename?: 'Whitelist';
  canWhitelist: Scalars['Boolean'];
  transactionsLeft: Scalars['Int'];
};

export type GetChallengesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChallengesQuery = { __typename?: 'Query', getChallenges: Array<{ __typename?: 'Challenge', id: number, blockchainAddress: string, state: string, payoutId: number } | null | undefined> };

export type GetChallengeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetChallengeQuery = { __typename?: 'Query', getChallenge: { __typename?: 'Challenge', id: number, blockchainAddress: string, state: string, payoutId: number, players?: Array<{ __typename?: 'Player', walletAddress: string, amountWonDecimal: string } | null | undefined> | null | undefined } };

export type GetChallengeHistoryQueryVariables = Exact<{
  wallet: Scalars['String'];
}>;


export type GetChallengeHistoryQuery = { __typename?: 'Query', getChallengeHistory: Array<{ __typename?: 'Challenge', id: number, blockchainAddress: string, state: string, payoutId: number } | null | undefined> };

export type CreateChallengeMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateChallengeMutation = { __typename?: 'Mutation', createChallenge: { __typename?: 'Challenge', id: number } };

export type StartChallengeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StartChallengeMutation = { __typename?: 'Mutation', startChallenge: string };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', discord?: string | null | undefined, discordUsername?: string | null | undefined } | null | undefined, getUsersNfts: Array<{ __typename?: 'Nft', mintid: string, name: string, img: string, xp: number, gamesPlayedToday: number } | null | undefined>, getGame: { __typename?: 'Game', resetTime: string, tournamentResetTime?: string | null | undefined, maxBattles: number } };

export type GetRedeemableAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRedeemableAmountQuery = { __typename?: 'Query', getRedeemableAmount: { __typename?: 'RedeemableAmounts', amountRedeemable: string, amountOnHold: string } };

export type RedeemMutationVariables = Exact<{ [key: string]: never; }>;


export type RedeemMutation = { __typename?: 'Mutation', redeem: string };

export type RecycleMutationVariables = Exact<{
  mintId: Scalars['String'];
  user: Scalars['String'];
}>;


export type RecycleMutation = { __typename?: 'Mutation', recycle: { __typename?: 'RecycleTransactions', txBONK: string } };

export type UpdateDiscordMutationVariables = Exact<{
  socials: IUpdateSocials;
}>;


export type UpdateDiscordMutation = { __typename?: 'Mutation', updateSocials: { __typename?: 'User', discord?: string | null | undefined } };

export type QueueMatchMutationVariables = Exact<{
  mintid: Scalars['String'];
}>;


export type QueueMatchMutation = { __typename?: 'Mutation', queueMatch: number };

export type QueueWagerMatchMutationVariables = Exact<{
  mintid: Scalars['String'];
}>;


export type QueueWagerMatchMutation = { __typename?: 'Mutation', createWager: number };

export type CreateRunWagerMutationVariables = Exact<{
  mintid: Scalars['String'];
}>;


export type CreateRunWagerMutation = { __typename?: 'Mutation', createRunWager: number };

export type VerifySignatureMutationVariables = Exact<{
  id: Scalars['Int'];
  signature: Scalars['String'];
}>;


export type VerifySignatureMutation = { __typename?: 'Mutation', verifySignature: boolean };

export type GetMatchQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMatchQuery = { __typename?: 'Query', getMatch: { __typename?: 'Match', winner?: string | null | undefined, wagerId?: string | null | undefined, isWager: boolean, battleBots: Array<{ __typename?: 'Nft', img: string, mintid: string, name: string, owner: string, xp: number, gamesPlayedToday: number } | null | undefined> } };

export type ResolveWagerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResolveWagerMutation = { __typename?: 'Mutation', resolveWager: number };


export const GetChallengesDocument = gql`
    query getChallenges {
  getChallenges {
    id
    blockchainAddress
    state
    payoutId
  }
}
    `;

export function useGetChallengesQuery(options?: Omit<Urql.UseQueryArgs<GetChallengesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChallengesQuery, GetChallengesQueryVariables>({ query: GetChallengesDocument, ...options });
};
export const GetChallengeDocument = gql`
    query getChallenge($id: Int!) {
  getChallenge(id: $id) {
    id
    blockchainAddress
    state
    payoutId
    players {
      walletAddress
      amountWonDecimal
    }
  }
}
    `;

export function useGetChallengeQuery(options: Omit<Urql.UseQueryArgs<GetChallengeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChallengeQuery, GetChallengeQueryVariables>({ query: GetChallengeDocument, ...options });
};
export const GetChallengeHistoryDocument = gql`
    query getChallengeHistory($wallet: String!) {
  getChallengeHistory(wallet: $wallet) {
    id
    blockchainAddress
    state
    payoutId
  }
}
    `;

export function useGetChallengeHistoryQuery(options: Omit<Urql.UseQueryArgs<GetChallengeHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChallengeHistoryQuery, GetChallengeHistoryQueryVariables>({ query: GetChallengeHistoryDocument, ...options });
};
export const CreateChallengeDocument = gql`
    mutation createChallenge {
  createChallenge {
    id
  }
}
    `;

export function useCreateChallengeMutation() {
  return Urql.useMutation<CreateChallengeMutation, CreateChallengeMutationVariables>(CreateChallengeDocument);
};
export const StartChallengeDocument = gql`
    mutation startChallenge($id: Int!) {
  startChallenge(id: $id)
}
    `;

export function useStartChallengeMutation() {
  return Urql.useMutation<StartChallengeMutation, StartChallengeMutationVariables>(StartChallengeDocument);
};
export const GetUserDocument = gql`
    query getUser {
  getUser {
    discord
    discordUsername
  }
  getUsersNfts {
    mintid
    name
    img
    xp
    gamesPlayedToday
  }
  getGame {
    resetTime
    tournamentResetTime
    maxBattles
  }
}
    `;

export function useGetUserQuery(options?: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
};
export const GetRedeemableAmountDocument = gql`
    query getRedeemableAmount {
  getRedeemableAmount {
    amountRedeemable
    amountOnHold
  }
}
    `;

export function useGetRedeemableAmountQuery(options?: Omit<Urql.UseQueryArgs<GetRedeemableAmountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRedeemableAmountQuery, GetRedeemableAmountQueryVariables>({ query: GetRedeemableAmountDocument, ...options });
};
export const RedeemDocument = gql`
    mutation redeem {
  redeem
}
    `;

export function useRedeemMutation() {
  return Urql.useMutation<RedeemMutation, RedeemMutationVariables>(RedeemDocument);
};
export const RecycleDocument = gql`
    mutation recycle($mintId: String!, $user: String!) {
  recycle(mintId: $mintId, user: $user) {
    txBONK
  }
}
    `;

export function useRecycleMutation() {
  return Urql.useMutation<RecycleMutation, RecycleMutationVariables>(RecycleDocument);
};
export const UpdateDiscordDocument = gql`
    mutation updateDiscord($socials: IUpdateSocials!) {
  updateSocials(socials: $socials) {
    discord
  }
}
    `;

export function useUpdateDiscordMutation() {
  return Urql.useMutation<UpdateDiscordMutation, UpdateDiscordMutationVariables>(UpdateDiscordDocument);
};
export const QueueMatchDocument = gql`
    mutation queueMatch($mintid: String!) {
  queueMatch(mint: $mintid)
}
    `;

export function useQueueMatchMutation() {
  return Urql.useMutation<QueueMatchMutation, QueueMatchMutationVariables>(QueueMatchDocument);
};
export const QueueWagerMatchDocument = gql`
    mutation queueWagerMatch($mintid: String!) {
  createWager(mint: $mintid)
}
    `;

export function useQueueWagerMatchMutation() {
  return Urql.useMutation<QueueWagerMatchMutation, QueueWagerMatchMutationVariables>(QueueWagerMatchDocument);
};
export const CreateRunWagerDocument = gql`
    mutation createRunWager($mintid: String!) {
  createRunWager(mint: $mintid)
}
    `;

export function useCreateRunWagerMutation() {
  return Urql.useMutation<CreateRunWagerMutation, CreateRunWagerMutationVariables>(CreateRunWagerDocument);
};
export const VerifySignatureDocument = gql`
    mutation verifySignature($id: Int!, $signature: String!) {
  verifySignature(id: $id, signature: $signature)
}
    `;

export function useVerifySignatureMutation() {
  return Urql.useMutation<VerifySignatureMutation, VerifySignatureMutationVariables>(VerifySignatureDocument);
};
export const GetMatchDocument = gql`
    query getMatch($id: Int!) {
  getMatch(id: $id) {
    battleBots {
      img
      mintid
      name
      owner
      xp
      gamesPlayedToday
    }
    winner
    wagerId
    isWager
  }
}
    `;

export function useGetMatchQuery(options: Omit<Urql.UseQueryArgs<GetMatchQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMatchQuery, GetMatchQueryVariables>({ query: GetMatchDocument, ...options });
};
export const ResolveWagerDocument = gql`
    mutation resolveWager($id: Int!) {
  resolveWager(id: $id)
}
    `;

export function useResolveWagerMutation() {
  return Urql.useMutation<ResolveWagerMutation, ResolveWagerMutationVariables>(ResolveWagerDocument);
};