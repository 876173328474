import { useWallet } from "@solana/wallet-adapter-react";
import { useMemo } from "react";
import { createClient, Provider as GQLProvider } from "urql";
import jwt from "jsonwebtoken";

export const GqlProvider: React.FC = ({ children }) => {
  const { publicKey } = useWallet();
  const client = useMemo(() => {
    if (publicKey !== null) {
      return createClient({
        url: process.env.REACT_APP_API!,
        fetchOptions: () => {
          const token = jwt.sign(
              { address: publicKey.toString(), api: "inv-v1" },
              publicKey.toString()
          );
          return {
            headers: { authorization: token ? `Bearer ${token}` : "" },
          };
        },
      });
    }

    return createClient({
      url: process.env.REACT_APP_API!,
    });
  }, [publicKey]);
  return <GQLProvider value={client}>{children}</GQLProvider>;
};
